import { CONSTRAINT_TYPES } from "./constants/constraints";

const replaceAtIndex = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + 1, string.length - index)
}

/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by a variable, i.e. a Subset Cohort
 */
export const getPlainLangVariableConstraint = (constraintType, constraintValues) => {
    let text = CONSTRAINT_TYPES[constraintType].label + ' ' || '';

    if (constraintType === 'BETWEEN') {
        return `${text}${constraintValues[0]} and ${constraintValues[1]}`;
    }
    if (constraintType === 'ANYOF') {
        // FIXME: ANYOF should be returning an array, much like BETWEEN (not an
        // immediate issue for now since ANYOF is currently disabled
        // functionality)
        const values = constraintValues.split(',');
        const lastValue = values.pop();
        let valuesString = values.join(', ');
        valuesString += `, or ${lastValue}`;
        return text + valuesString;
    }
    if (constraintType === 'PEDIGREE') {
        const values = constraintValues.split(',');
        const pedConstraintText =
                values[2] === "<=" ? "at most " :
                values[2] === ">=" ? "at least " :
                values[2] === "=" ? "exactly " :
                "unknown ";
                
        return text + values[0] + " for " + pedConstraintText + values[3] + " members of pedigrees of minimum size " + values[1];
    }

    if (constraintValues !== null && typeof constraintValues !== "undefined") {
        return text + constraintValues;
    } else {
        return text;
    }
}

/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by its relationship to another cohort, i.e. a Compound Cohort
 */
export const getPlainLangRelationConstraint = (constraintType, constraintValues) => {
    if (!constraintType || !constraintValues) return '';

    const values = constraintValues.split(',');
    let text = replaceAtIndex(constraintType, 0, values[0])
    text = replaceAtIndex(text, text.length - 1, values[1])

    return text;
}

export const getPlainLangCompoundRelationship = (relationship) => {
    switch (relationship) {
        case "intersect":
        case "INTERSECT":
            return "Intersection";
        case "subtract":
        case "SUBTRACT":
            return "Subtraction";
        case "union":
        case "UNION":
            return "Union";
        default:
            return "Unknown Compound Relationship"
    }
}


const getQualifier = (qualifier) => {
    switch(qualifier) {
        case "min": 
            return "at least";
        case "max":
            return "at most";
        case "equal":
            return "exactly";
        default: 
            return qualifier; // or return some kind of error message?
    }
}

const getRelationship = (relationship) => {
    switch(relationship) {
        case "family": 
            return "family members";
        case "siblings":
            return "siblings";
        default: 
            return relationship; // or return some kind of error message?
    }
}



/**
 * Gets the "plain" language description of the constraints 
 * for a cohort constrained by pedigree, i.e. a Pedigree Set
 */
/**
 * 
 * @todo
 * This may need to be updated as I imagine the actual pedigree
 * constraints could be different.
 * 
 * For now, this assumes the cohort has:
 *      constraint_type: "PEDIGREES"
 * and something like: 
 *      constraint_values: {
 *          "include": true,
 *          "qualifier": "min",
 *          "quantity": 0,
 *          "relationship": "family",
 *          "cohort": ""
 *      }
 */
export const getPlainLangPedigreeConstraint = constraintValues => {

    if (!constraintValues) return "Include all relatives of individuals in this Pedigree Set";
    
    const { include, qualifier, quantity, relationship, cohort } = constraintValues;
    
    return `${include === "include" ? "Include" : "Exclude"} relatives of individuals in this Pedigree Set with ${getQualifier(qualifier)} ${quantity} ${getRelationship(relationship)} ${cohort ? `in the ${cohort} Pedigree Set` : ""}.`
    
}
