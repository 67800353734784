// Dispatch table for controlling the display and formatting of variable fields.

import { Link } from "react-router-dom"
import { Count, QuestionNewlines } from "../components/Typography";
import { getPathFromRouteName } from ".";
import { DowncodeTableReadonly, ConstructMappingTable } from "../components/ClinicalConstructs";
import { ClinicalConstructContext } from "../contexts/ClinicalConstructContext";
import { VARIABLE_TYPES, VIEW_VARIABLE, VIEW_CLINICAL_CONSTRUCT, PDF_VIEW } from "./constants";


// Column formatting dispatch table.
// Each variable table column consists of a transformation of one or more
// variable fields, and a descriptive name. The idea is to provide what JSX
// fragments get stuffed into table cells (or whatever/whereever else these
// things will go to).
export const variableFieldDisplay = {
    nameandcount: {
        fieldname: "Name",
        isCount: true,
        xform: v => <>{v.variable_name} <Count>{v.observations}</Count></>
    },
    namenocount: {
        fieldname: "Name",
        xform: v => <>{v.variable_name}</>
    },
    observations: {
        fieldname: "Observations",
        isCount: true,
        xform: v => <>{v.observations}</>
    },
    fullquestion: {
        fieldname: "Question/Description",
        width: 600,
        xform: v => {
            return (
                <>
                    <QuestionNewlines variable={v} field="explicit"/>
                    <QuestionNewlines variable={v} field="question"/>
                    <QuestionNewlines variable={v} field="implicit"/>
                </>)
        }
    },
    answerandtype: {
        fieldname: "Answers and Answer Type",
        xform: v => {
                return (
                    <>
                    {(() => {switch (v.data_type) {
                        case VARIABLE_TYPES.CATEGORICAL:
                        case VARIABLE_TYPES.MULTICATEGORY:
                            if (v.value_mapping) {
                                return (v.data_type === "multicategory" ? "Multi-value " : "") + "Categorical: " + v.value_mapping.join(', ');
                            }
                            break;
                        case VARIABLE_TYPES.DICHOTOMOUS:
                            return "Dichotomous";
                        case VARIABLE_TYPES.NUMBERRANGE:
                            if (v.possible_values) {
                                return "Number Range: " + v.possible_values[0] + "-" + v.possible_values[1];
                            }
                            break;
                        case VARIABLE_TYPES.DATE:
                            return "Date: YYYYMMDD";
                        case VARIABLE_TYPES.IDENTIFIER:
                            return "Identifier text (free-form)";
                        case VARIABLE_TYPES.TEXT:
                            return "Text (free-form)";
                        case VARIABLE_TYPES.NULL:
                        default:
                            break
                    }
                    // Fall back to regex representation
                    return "REGEX:/" + v.answer_validation + "/ (" + v.answer_validation_desc + ")";
                    })()}
                </>)
                }
    },
    topanswers: {
        fieldname: "Top Answers",
        isCount: true,
        xform: v => {
            if (!v.top_answers) {
                return (
                    <>(undefined)</>
                );
            }
            const maxIterations = 10;
            return (
                <ul>
                    {v.top_answers.map((keyvalue, i) => {
                        if ( i >= maxIterations ) {
                            return "";
                        } else {
                            return (
                                <li key={i} className="mt-0">
                                    {keyvalue[0]}: {keyvalue[1]}
                                </li>
                            );
                        }
                    })}
                </ul>
            );
        }
    },
    contributors: {
        fieldname: "Contributing Variables",
        xform: v => {
            if (v.variable_type === "variable") {
                // regular FHV
                return (
                    <ul>
                        {v.contributors.map((contribvar, i) => {
                            let displaycontents = (<>
                                    {contribvar.variable_name} (
                                    {contribvar.instrument === contribvar.version ? contribvar.version : contribvar.instrument + " " + contribvar.version},
                                    section {contribvar.section},
                                    page {contribvar.TOC_page}
                                    {contribvar.item ? ", item " + contribvar.item : ""})
                                </>);
                            return (<li key={i} className="mt-0">
                                {contribvar.PDF_file === null || contribvar.PDF_file === "No_Interview_Available.pdf" ? <>{displaycontents} (no codebook available)</> : <Link to={getPathFromRouteName(PDF_VIEW, { pdf: contribvar.PDF_file, pagenum: contribvar.PDF_page })} target="_blank">{displaycontents}<i className="pl-1 fas fa-external-link-alt"></i></Link>}
                            </li>)
                            })
                        }
                     </ul>)
            } else {
                return (
                    <ul>
                        {v.contributors.map((contribvar, i) => {
                            return (<li key={i} className="mt-0">
                                <Link to={getPathFromRouteName(VIEW_VARIABLE, { id: contribvar})} target="_blank">{contribvar}<i className="pl-1 fas"></i></Link>
                            </li>)
                            })
                        }
                    </ul>)
            }
        }
    },
    validationregex: {
        fieldname: "Answer Validation Regex",
        xform: v => {
            if (v.variable_type === "variable") {
                return (
                    <>/{v.answer_validation}/<br/>({v.answer_validation_desc})</>
                )
            } else {
                return "N/A";
            }
        }
    },
    literalvalues: {
        fieldname: "Literal Variable Values",
        xform: v => {
            if ([VARIABLE_TYPES.CATEGORICAL, VARIABLE_TYPES.MULTICATEGORY].includes(v.data_type) && v.variable_type === "variable") {
                return (
                    <ul>
                        {v.possible_values.map((val, i) => {
                            return (
                                <li key={i} className="mt-0">
                                    {v.possible_values[i]}: {v.value_mapping[i]}
                                </li>
                            )})
                        }
                    </ul>
                )
            } else {
                return "N/A";
            }
        }
    },
    customvariable: {
        fieldname: "Custom Variable Construction",
        xform: v => {
            if (v.variable_type === "variable") {
                return "N/A"
            } else if (v.variable_type === "downcode") {
                return (
                    <DowncodeTableReadonly construct={v} />
                )
            } /*else if (v.variable_type === "merge") {
            // FIXME: the "variables" expected aren't just the list of names,
                // but complete variable information.
                return (
                    <ClinicalConstructContext.Provider
                        value={{
                            defaultValue: v.default_value,
                            values: v.possible_values,
                            variables: v.contributors
                            }}>
                        <ConstructMappingTable
                            readonly={true}
                        />
                    </ClinicalConstructContext.Provider>
                    )
            }*/ else {
                return (
                    <>
                        (WIP; <a href={getPathFromRouteName(VIEW_CLINICAL_CONSTRUCT, { id: v.variable_lookup_id })}>click here for details</a>)
                    </>
                )
            }
        }
    }
}
