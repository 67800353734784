import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../components/Buttons";
import { ConstructMappingTable, /*CopyConstructButton, */ DeleteConstructButton, DowncodeTableReadonly, MultiLineConstraintReadonly } from "../../components/ClinicalConstructs";
import { Alert, Icon, Spacer } from "../../components/GeneralUI";
import { VariableCollectionForm } from "../../components/Variables";
import { ClinicalConstructContext } from "../../contexts";
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks";
import { InnerPage } from "../../layout"

import api from '../../utils/api'
import { ALL_VARIABLES } from "../../utils/constants";

export const ViewClinicalConstruct = () => {

    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    const [ construct, setConstruct ] = useState({});
    const [ editing, setEditing ] = useState(false);
    const [ name, setName ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ variables, setVariables ] = useState([]);

    const [ loading, setLoading ] = useState(true);

    const clinicalConstructContextValue = { 
        defaultValue: construct ? construct.default_value : null,
        values: construct ? construct.value_definitions : [],
        variables,
    };

    // Update state if we go to a new construct
    useEffect(() => {
        setName(construct.variable_name || "");
        setDescription(construct.question || "")
    }, [construct])


    // Get the cohort details and records
    useEffect(() => {

        api.getConstruct(id)
            .then(res => {
                //if (cancelAsyncCall()) return null;
                
                const construct = res.data.custom_vars[0];

                setConstruct(construct);
                
                // Get all the variable data from the names
                const getVariables = construct.contributors.map(v => api.getVariableByName(v))

                Promise.all(getVariables)
                    .then(res => {
                        //if (cancelAsyncCall()) return null;

                        setVariables(res.map(r => r.data[0]));

                    })
                    .catch((err) => {
                        toast.error(errorCheckText(err, 'Unable to get the variables included in this cohort'))
                    })
                    .finally(() => setLoading(false))
                
            })
            .catch((err) => {
                setLoading(false)
                toast.error(errorCheckText(err, `Unable to find Custom Variable with id ${id}.`))
            })
        
    }, [/*cancelAsyncCall, */id, errorCheckText])


    // Submit edit form for name and description
    const handleSubmit = (formValues) => {
        const { description, name } = formValues;

        if (name || description) {
            api.updateConstruct({
                    ...formValues,
                    id,
                })
                .then(res => {
                    
                    setEditing(false)
                    toast.success('Changes saved.')

                    api.getConstruct(id)
                        .then(res => {
                            setConstruct(res.data.constructs[0]);
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, 'Unable to update changes on page. Please reload this window.'))
                        })
                        
                })
                .catch(err => {
                    toast.error(errorCheckText(err, 'Unable to save Custom Variable.'))
                });
        }
        
    }

    // Reset form for name and description
    const handleReset = () => {
        setEditing(false)
    }

    // No construct found
    if (!construct || !construct.variable_name) {
        return (
            <InnerPage
                title="View Custom Variable"
                loading={loading}
            >

                <Alert variant="warning">No Custom Variable was found with the id "{id}"</Alert>

            </InnerPage>
        )
    }

    // No variables found
    if (!variables || !variables.length) {
        return (
            <InnerPage
                title="View Custom Variable"
                loading={loading}
            >

                <Alert variant="warning">The variables included in this Custom Variable could not be found.</Alert>

            </InnerPage>
        )
    }

    // Done loading - show data
    return (
        <InnerPage
            title={construct.variable_name}
            intro={construct.question}
            loading={loading}
            editButton={
                <Button classes="btn--text-link" onClick={() => setEditing(true)}>
                    Edit <Icon name="pencil-alt" />
                </Button>
            }
            actions={
                <div className="icon-btn-group">
                    <DeleteConstructButton 
                        construct={construct}
                        returnRoute={ALL_VARIABLES}
                    />
                    {/* Removing for now per issue #254
                    <CopyConstructButton
                        construct={construct}
                    />
                    */}
                </div>
            }
        >

            {/* Show variables & show name/description edit */}
            <VariableCollectionForm
                componentLabel="Custom Variable"
                description={description}
                editable={editing}
                name={name}
                onReset={editing ? handleReset : null}
                onSubmit={editing ? handleSubmit : null}
                setName={editing ? setName : null}
                nameType={editing ? "variable_name" : null}
                setDescription={editing ? setDescription : null}
                variables={variables}
                variableActions={[]}
            />

            <Spacer size={3} />


            {/* Values & definitions */}
            <ClinicalConstructContext.Provider value={clinicalConstructContextValue}>


                    {/* Downcode Value Name/Descriptions */}
                    <section>
                        
                        <h3 className="h5">Values</h3>


                        <section className="section--blue">
                            
                            { construct.variable_type !== "multiple" ?

                                // Downcode and Two-Var Construct Names/Descriptions
                                // (Much simpler than the Multivariable ones)
                                <ul>
                                    { construct.value_definitions.map( (val,i) => (
                                        <li key={val.name} className="my-3">

                                            <div className="font-weight-600 mb-2">{val.name}</div> 
                                            
                                            <div className="mb-2">{val.description}</div>
                                        </li>
                                    ))}
                                </ul>

                            :

                                // Multivariable Value Names/Descriptions & Definitions
                                construct.value_definitions.map( (val,i) => (
                                    <div key={val.name} className="my-3">

                                        {/* Name */}
                                        <div className="font-weight-600 mb-2">{val.name}</div> 
                                        
                                        {/* Description */}
                                        <div className="mb-2">{val.description}</div>


                                        {/* Definition */}
                                        <div className="complex-value-definition">

                                            {
                                                val.definition.constraints.map((row, j) => (

                                                    <div key={j} className="complex-value-definition__var mb-0">
                                                        <div className={`container px-2`}>
                                                            {
                                                                j !== 0 &&
                                                                    <div className="row mb-3">
                                                                        <div className={`align-items-center px-2`}> 
                                                                        
                                                                            {val.definition.relationship}
                                                                        
                                                                        </div>    
                                                                    </div>
                                                            }
                                                            
                                                            {/* 
                                                            * Each of these is a "group" constraining one variable; 
                                                            * They have the light blue background in the UI 
                                                            */}
                                                            <MultiLineConstraintReadonly
                                                                index={i}
                                                                gutterSize={2} 
                                                                constraintGroup={row}
                                                                readonly
                                                            />

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>
                                ))
                            }
                        </section>

                        <Spacer size={3} />
                    
                    </section>


                {/* Downcode Value Definitions */}
                { construct.variable_type === "downcode" &&
                    <DowncodeTableReadonly construct={construct} />
                }


                {/* Two-var Value Definitions */}
                { construct.variable_type === "merge" &&
                
                    <ConstructMappingTable
                        readonly={!editing}
                        title="Value Definitions"
                    />

                }


            </ClinicalConstructContext.Provider>



        </InnerPage>
    )
}
